:root {
  --color-primary: #304ffe;
  --color-gradient: 45deg, #303b7d 0%, #566ad9 100%;
  --color-text: #000;
  --color-white: #fff;
  --bg-color: #e6e9ff;
  --dark-text: #101828;
  --table-border: #d0d5dd;
  --dark-blue: #021ec2;
  --border-color: #d7dbe4;
  --captcha-bg-color: #f9f9f9;
  --input-placeholder-color: #667085;
  --intro-page-background-color: #f7f8fd;

  --embark-image-background: lightgray 0px -1.596px / 100% 100.547% no-repeat;
  --filter-image-shadow: drop-shadow(0px 29px 40px rgba(0, 0, 0, 0.62));

  --topbar-box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);

  --vms-box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  --footer-radial-gradient: radial-gradient(
    188.6% 145.01% at -0.44% 101.18%,
    #021ec2 0%,
    rgba(0, 0, 0, 0) 100%
  );
  --footer-linear-gradient: linear-gradient(45deg, #303b7d 0%, #566ad9 100%),
    #304ffe;

  --card-radial-gradient: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  --form-page-frame-image: lightgray 50% / cover no-repeat;
  --form-page-radial-gradient: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  --form-page-container-box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Roboto !important;
  font-style: normal;
}

.wrapper {
  padding: 0px 64px;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  color: unset;
}

/* For screens wider than 1920px */
@media screen and (min-width: 1920px) {
  h1 {
    font-size: 65px !important;
    font-family: Roboto !important;
  }

  h2 {
    font-size: 40px !important;
    font-family: Roboto !important;
  }

  h3 {
    font-size: 27px !important;
    font-family: Roboto !important;
  }

  h4 {
    font-size: 24px !important;
    font-family: Roboto !important;
  }

  h5 {
    font-size: 22px !important;
    font-family: Roboto !important;
  }

  p,
  label,
  span,
  h6 {
    font-size: 16px !important;
    font-family: Roboto !important;
  }
}

/* For screens wider than 1536px but less than 1920px */
@media screen and (min-width: 1536px) and (max-width: 1919px) {
  h1 {
    font-size: 49px !important;
    font-family: Roboto !important;
  }

  h2 {
    font-size: 30px !important;
    font-family: Roboto !important;
  }

  h3 {
    font-size: 20px !important;
    font-family: Roboto !important;
  }

  h4 {
    font-size: 18px !important;
    font-family: Roboto !important;
  }

  h5 {
    font-size: 16px !important;
    font-family: Roboto !important;
  }

  p,
  label,
  span,
  h6 {
    font-size: 14px !important;
    font-family: Roboto !important;
  }
}

/* For screens less than 1536px */
@media screen and (max-width: 1535px) {
  h1 {
    font-size: 44px !important;
    font-family: Roboto !important;
  }

  h2 {
    font-size: 26px !important;
    font-family: Roboto !important;
  }

  h3 {
    font-size: 18px !important;
    font-family: Roboto !important;
  }

  h4 {
    font-size: 16px !important;
    font-family: Roboto !important;
  }

  h5 {
    font-size: 14px !important;
    font-family: Roboto !important;
  }

  p,
  label,
  span,
  h6 {
    font-size: 12px !important;
    font-family: Roboto !important;
  }
}
